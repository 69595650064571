
import { defineComponent, reactive, ref, watch } from "@vue/runtime-core";
import API from "@/API/taskCenter/index";
import useModel from "@/hooks/useModel";
const columns = [
  {
    title: "文件名称",
    dataIndex: "filename",
    width: 80,
  },
  {
    title: "任务结果",
    dataIndex: "taskResult",
    width: 100,
  },
];
const tableHeight = window.innerHeight - 200;
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    timeStamp: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const _visible = useModel(props, "visible");
    const detail = ref({});
    const tableData = ref([]);
    watch(
      () => props.timeStamp,
      () => {
        API.getTaskCenterDetail(props.id).then((res) => {
          detail.value = res;
          tableData.value = res.details
        });
      }
    );
    const getParams = reactive({
      taskType: "",
      operTime: "",
      status: "",
      taskBeginTime: "",
      taskDoneTime: "",
    });
    const onClose = () => {
      _visible.value = false
    };
    return {
      columns,
      _visible,
      detail,
      getParams,
      tableData,
      tableHeight,
      onClose,
    };
  },
});
